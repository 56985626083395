import React, { useEffect, useRef } from "react";
import styles from "./Hero.module.css";

const Hero = () => {
  const heroRef = useRef(null);
  const imageRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const heroElement = heroRef.current;
    const imageElement = imageRef.current;

    const scrollHandler = () => {
      const scrollPosition = window.pageYOffset;
      const heroOffset = heroElement.offsetTop;
      const scrollPercentage =
        (scrollPosition - heroOffset) / window.innerHeight;

      imageElement.style.transform = `translateY(${scrollPercentage * 50}px)`;
      heroElement.style.opacity = 1 - scrollPercentage;
    };

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const phoneImage = new Image();
    phoneImage.src =
      "https://physiotattava-website.s3.eu-central-1.amazonaws.com/Group_2085663203__2_-removebg-preview.png";

    phoneImage.onload = () => {
      canvas.width = phoneImage.width;
      canvas.height = phoneImage.height;

      const messages = [
        "Hey, Akash! I'm Dr Riya,",
        "and I'll guide you to",
        "analyze your possible",
        "symptoms. Are You",
        "Ready?",
      ];

      let fullText = "";
      let currentCharIndex = 0;
      let isTyping = true;
      const typingSpeed = 50;

      function drawFrame() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(phoneImage, 0, 0, canvas.width, canvas.height);

        ctx.save();

        // Add white background
        ctx.fillStyle = "#F1F5F9";
        ctx.fillRect(
          canvas.width * 0.13,
          canvas.height * 0.25,
          canvas.width * 0.6,
          canvas.height * 0.25
        );

        // Position the text
        ctx.translate(canvas.width * 0.15, canvas.height * 0.28);

        // Apply a slight rotation for the tilted effect
        ctx.rotate(-0.03); // Adjust this value to change the tilt angle

        ctx.font = "15px Arial";
        ctx.fillStyle = "#005477";
        ctx.textAlign = "left";

        const lineHeight = 22;
        fullText.split("\n").forEach((line, index) => {
          ctx.fillText(line, 0, index * lineHeight);
        });

        ctx.restore();
      }

      function typeWriter() {
        if (isTyping) {
          if (currentCharIndex < messages.join("\n").length) {
            fullText = messages.join("\n").substring(0, currentCharIndex + 1);
            currentCharIndex++;
            drawFrame();
            setTimeout(typeWriter, typingSpeed);
          } else {
            isTyping = false;
            setTimeout(typeWriter, 3000);
          }
        } else {
          if (currentCharIndex > 0) {
            fullText = messages.join("\n").substring(0, currentCharIndex - 1);
            currentCharIndex--;
            drawFrame();
            setTimeout(typeWriter, typingSpeed / 2);
          } else {
            isTyping = true;
            setTimeout(typeWriter, 1000);
          }
        }
      }

      drawFrame();
      typeWriter();
    };
  }, []);

  return (
    <section className={styles.hero} ref={heroRef}>
      <div className={styles.heroContent}>
        <div className={styles.textContent}>
          <h1 className={styles.title}>
            Physio at <br /> Your Fingertips
          </h1>
          <p className={styles.description}>
            Experience Personalized Physiotherapy with Real-Time Feedback and
            Expert Support.
          </p>
          <a
            href="https://www.app.physiotattva247.com/get-started/"
            className={styles.downloadButton}
            target="_blank"
            rel="noopener noreferrer"
          >
            Speak to AI Dr. Riya
          </a>
        </div>
        <div className={styles.imageContainer} ref={imageRef}>
          <canvas ref={canvasRef} className={styles.phoneImage} />
          <div className={styles.backgroundShape}></div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
