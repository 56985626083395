import { useEffect, useCallback, useRef } from "react";
import ReactPixel from "react-facebook-pixel";

const PIXEL_ID = "446073067879877"; // Replace with your actual Pixel ID

const usePixel = () => {
  const isInitialized = useRef(false);

  useEffect(() => {
    if (!isInitialized.current) {
      ReactPixel.init(PIXEL_ID, null, {
        autoConfig: true,
        debug: false,
      });
      console.log("Facebook Pixel initialized");
      isInitialized.current = true;
    }
  }, []);

  const fbtrackPageView = useCallback((path) => {
    console.log(`Pixel - Tracking page view: ${path}`);
    ReactPixel.pageView();
    console.log("tracked");
  }, []);

  const fbtrackEvent = useCallback((eventName, params = {}) => {
    console.log(`Pixel - Tracking event: ${eventName}`);
    console.log("  Params:", params);
    ReactPixel.track(eventName, params);
  }, []);

  const fbtrackViewContent = useCallback((params = {}) => {
    console.log("Pixel - Tracking ViewContent");
    console.log("  Params:", params);
    ReactPixel.track("ViewContent", {
      content_type: "doctor_listing",
      content_name: "Doctor Listing Page",
      ...params,
    });
  }, []);

  const fbtrackAddToCart = useCallback((params = {}) => {
    console.log("Pixel - Tracking AddToCart");
    console.log("  Params:", params);
    ReactPixel.track("AddToCart", {
      content_type: "doctor",
      content_name: params.doctorName,
      content_ids: [params.doctorId],
      currency: "USD",
      value: params.price,
      ...params,
    });
  }, []);

  const fbtrackAddPaymentInfo = useCallback((params = {}) => {
    console.log("Pixel - Tracking AddPaymentInfo");
    console.log("  Params:", params);
    ReactPixel.track("AddPaymentInfo", {
      content_category: "Medical Consultation",
      currency: "USD",
      value: params.amount,
      ...params,
    });
  }, []);

  const fbtrackPurchase = useCallback((params = {}) => {
    console.log("Pixel - Tracking Purchase");
    console.log("  Params:", params);
    ReactPixel.track("Purchase", {
      content_type: "medical_consultation",
      content_ids: [params.doctorId],
      content_name: params.consultationDetails,
      currency: "USD",
      value: params.amount,
      ...params,
    });
  }, []);

  return {
    fbtrackPageView,
    fbtrackEvent,
    fbtrackViewContent,
    fbtrackAddToCart,
    fbtrackAddPaymentInfo,
    fbtrackPurchase,
  };
};

export default usePixel;
