import React from 'react';
import styles from './Testimonials.module.css';

const Testimonials = () => {
  const testimonials = [
    { id: 1, imageSrc: "https://cadabams-diagnostics-assets.s3.ap-south-1.amazonaws.com/dev-assets/1.jpeg" },
    { id: 2, imageSrc: "https://cadabams-diagnostics-assets.s3.ap-south-1.amazonaws.com/dev-assets/2.jpeg" },
    { id: 3, imageSrc: "https://cadabams-diagnostics-assets.s3.ap-south-1.amazonaws.com/dev-assets/3.jpeg" },
    { id: 4, imageSrc: "https://cadabams-diagnostics-assets.s3.ap-south-1.amazonaws.com/dev-assets/4.jpeg" },
  ];

  return (
    <section className={styles.testimonials}>
      <h2 className={styles.title}>
        From enduring pain to embracing progress, AI is the cornerstone of the recovery journeys of many.
      </h2>
      <div className={styles.testimonialGrid}>
        {testimonials.map((testimonial) => (
          <img
            key={testimonial.id}
            src={testimonial.imageSrc}
            alt={`Testimonial ${testimonial.id}`}
            className={styles.testimonialImage}
          />
        ))}
      </div>
    </section>
  );
};

export default Testimonials;