import React from "react";
import styles from "./Footer.module.css";
import usePixel from "../../hooks/usePixel";
export default function Footer() {
  const { fbtrackPageView, fbtrackViewContent } = usePixel();

  const handleBookConsultation = () => {
    fbtrackViewContent({
      content_type: "doctor_listing",
      content_name: "Doctor Listing Page",
    });
    window.open("https://www.app.physiotattva247.com/get-started", "_blank");
  };

  return (
    <footer className={styles.footer}>
      <h2 className={styles.footerHeading}>
        Get Back To Living Your Best Life!
      </h2>
      <button
        className={styles.downloadButton}
        onClick={handleBookConsultation}
      >
        Speak to AI Dr. Riya
      </button>
    </footer>
  );
}
