import React, { useEffect, useRef } from "react";
import styles from "./MainContent.module.css";
import RecoverySteps from "../RecoverySteps/RecoverySteps";
import FeatureCard from "../FeatureCard/FeatureCard";
import Testimonials from "../Testimonials/Testimonials";
import ContactForm from "../ContactForm/ContactForm";
import usePixel from "../../hooks/usePixel";

const MainContent = () => {
  const containerRef = useRef(null);
  const featuresRef = useRef(null);
  const whyChooseRef = useRef(null);
  const { fbtrackPageView, fbtrackAddToCart, fbtrackViewContent } = usePixel();

  const pathname = window.location.pathname;

  useEffect(() => {
    fbtrackPageView(pathname);

    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      const elements = [
        containerRef.current,
        featuresRef.current,
        whyChooseRef.current,
      ];

      elements.forEach((el) => {
        if (el) {
          const rect = el.getBoundingClientRect();
          const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
          if (isVisible) {
            el.classList.add(styles.visible);
          }
        }
      });

      // Tilt effect for container
      if (containerRef.current && window.innerWidth > 768) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const containerOffset = containerRect.top + window.pageYOffset;
        const tiltPercentage = Math.max(
          0,
          Math.min(
            (scrollPosition - containerOffset + window.innerHeight) /
              window.innerHeight,
            1
          )
        );
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleBookConsultation = () => {
    fbtrackViewContent({
      content_type: "doctor_listing",
      content_name: "Doctor Listing Page",
    });

    window.open("https://www.app.physiotattva247.com/get-started", "_blank");
  };

  return (
    <main className={styles.mainContent}>
      <section
        ref={containerRef}
        className={`${styles.container} ${styles.animatedSection} ${styles.visible}`}
      >
        <div className={styles.contentWrapper}>
          <div className={styles.imageColumn}>
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/03bd57a5-0f2f-4b39-8b08-91a5777278d0?apiKey=fd9c0163575d41b080a751265adc9b1c&"
              alt="Physical recovery illustration"
              className={styles.mainImage}
            />
          </div>
          <div className={styles.textColumn}>
            <h2 className={styles.heading}>
              Physical Recovery Has Never Been So Seamless!
            </h2>
            <p className={styles.subheading}>
              At PhysioTattva 247, we believe everyone deserves to live their
              best life without pain or limitations holding them back.
            </p>
            <button
              className={styles.ctaButton}
              onClick={handleBookConsultation}
            >
              Speak to AI Dr. Riya
            </button>
          </div>
        </div>
      </section>

      <RecoverySteps />

      <section
        ref={featuresRef}
        className={`${styles.featuresSection} ${styles.animatedSection}`}
      >
        <div
          ref={whyChooseRef}
          className={`${styles.whyChooseSection} ${styles.animatedSection}`}
        >
          <div className={styles.circleImg}></div>
          <div style={{ padding: "4rem" }} className={styles.whyChooseContent}>
            <h2 className={styles.featuresTitle}>
              Why Choose Physiotattva 247?
            </h2>
            <p className={styles.featuresDescription}>
              Our expert team offers personalized physiotherapy programs
              tailored to address chronic pain and mobility, helping you achieve
              optimal health through evidence-based practices
            </p>
          </div>
          <div
            style={{ padding: "4rem" }}
            className={styles.whyChooseImageContainer}
          >
            <img
              src="https://physiotattava-website.s3.eu-central-1.amazonaws.com/Group+1171275421+(1).png"
              alt="Physiotherapy team"
              className={styles.whyChooseImage}
            />
          </div>
        </div>
        <div className={styles.featureCards}>
          <FeatureCard
            imageSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/369b1ce2bbb890f6f7619150a67d7a4eca509378c1f36c73c2d1ae167bcc6ca4?apiKey=fd9c0163575d41b080a751265adc9b1c&"
            title="Guided by Experts"
            description="Get accurate guidance backed by our experts."
            iconSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/d74333df-49dd-4014-8b52-59d6f51bbe56?apiKey=fd9c0163575d41b080a751265adc9b1c&"
          />
          <FeatureCard
            imageSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/66302cc10667c39e503aca9e90c8a37a98e1e7e8da705dd06a653b6d3da33dcd?apiKey=fd9c0163575d41b080a751265adc9b1c&"
            title="Real-Time Feedback"
            description="Receive instant feedback & posture corrections."
            iconSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/9358dce1-9e46-4414-8c1d-3331b926d2ed?apiKey=fd9c0163575d41b080a751265adc9b1c&"
          />
          <FeatureCard
            imageSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/39c23d2fc621a8325884fd0bf27db321057110edbf36ae5679d0b5819ca51cd4?apiKey=fd9c0163575d41b080a751265adc9b1c&"
            title="Optimized Recovery"
            description="Regular AI progress tracking & expert guidance."
            iconSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/729ef218-8c72-4598-a24a-2165c0c23106?apiKey=fd9c0163575d41b080a751265adc9b1c&"
          />
          <FeatureCard
            imageSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/f219ef550dd2d77e211f06f2ccd66621e86837cf6d6775e881ef840d71069e0a?apiKey=fd9c0163575d41b080a751265adc9b1c&"
            title="24x7 Support"
            description="Access personalized exercise programs anywhere, anytime!"
            iconSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/f88a074b-5817-4a69-8211-bac311f81f06?apiKey=fd9c0163575d41b080a751265adc9b1c&"
          />
        </div>
      </section>

      <Testimonials />

      <ContactForm />
    </main>
  );
};

export default MainContent;
