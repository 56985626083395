import React, { useEffect, useRef } from 'react';
import styles from './RecoverySteps.module.css';

const RecoverySteps = () => {
  const stepsRef = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.animate);
        } else {
          entry.target.classList.remove(styles.animate);
        }
      });
    }, options);

    stepsRef.current.forEach((step) => {
      if (step) observer.observe(step);
    });

    return () => {
      stepsRef.current.forEach((step) => {
        if (step) observer.unobserve(step);
      });
    };
  }, []);

  return (
    <section className={styles.recoverySteps}>
      <h2 className={styles.title}>
        <span className={styles.bold}>How It Works:</span>
        <br />
        <span className={styles.light}>3 Simple Steps to Recovery</span>
      </h2>
      <div className={styles.stepsContainer}>
        <div
          ref={(el) => (stepsRef.current[0] = el)}
          className={`${styles.step} ${styles.stepRight} ${styles.fadeInRight}`}
        >
          <div className={styles.stepContent}>
            <h3 className={styles.stepTitle}>Describe Your Symptoms:</h3>
            <p className={styles.stepDescription}>
              Share your symptoms with Dr. Riya (AI Chatbot) for instant
              guidance and a personalized assessment.
            </p>
          </div>
          <img
            src="https://physiotattava-website.s3.eu-central-1.amazonaws.com/iPhone+15+Pro+Portrait+Mockup+(1).png"
            alt="AI Symptom Checker"
            className={styles.phoneImage}
          />
        </div>
        <div className={styles.dottedLine}></div>
        <div
          ref={(el) => (stepsRef.current[1] = el)}
          className={`${styles.step} ${styles.stepLeft} ${styles.fadeInLeft}`}
        >
          <img
            src="https://physiotattava-website.s3.eu-central-1.amazonaws.com/iPhone+15+Pro+Portrait+Mockup+(3).png"
            alt="Video Consultation"
            className={styles.phoneImage}
          />
          <div className={styles.stepContent}>
            <h3 className={styles.stepTitle}>Set Up a Consultation:</h3>
            <p className={styles.stepDescription}>
              Pick a time for a video call with our expert physiotherapists that
              fits your schedule.
            </p>
          </div>
        </div>
        <div className={styles.dottedLine}></div>
        <div
          ref={(el) => (stepsRef.current[2] = el)}
          className={`${styles.step} ${styles.stepRight} ${styles.fadeInRight}`}
        >
          <div className={styles.stepContent}>
            <h3 className={styles.stepTitle}>Start Your Recovery Journey:</h3>
            <p className={styles.stepDescription}>
              Get a personalized recovey plan and track your advance using
              motion tracking.
            </p>
          </div>
          <img
            src="https://physiotattava-website.s3.eu-central-1.amazonaws.com/iPhone+15+Pro+Black+Titanium+Mockup+Portrait.png"
            alt="Recovery Journey"
            className={styles.phoneImage}
          />
        </div>
      </div>
    </section>
  );
};

export default RecoverySteps;