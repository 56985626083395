import React, { useEffect, useRef, useState } from "react";
import styles from "./ContactForm.module.css";

const ContactForm = () => {
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    consultation_type: "Virtual/online consultation",
    email: "",
    asset_key:
      "10225d1d52d93710486bc64ab9f65fccef25cadfb68f0ea2c39e5e064326f724",
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.visible);
        }
      },
      { threshold: 0.1 }
    );

    if (formRef.current) {
      observer.observe(formRef.current);
    }

    return () => {
      if (formRef.current) {
        observer.unobserve(formRef.current);
      }
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiData = new FormData();
    apiData.append("contact[first_name]", formData.first_name);
    apiData.append("contact[last_name]", formData.last_name);
    apiData.append("contact[mobile_number]", formData.mobile_number);
    apiData.append(
      "contact[custom_field][cf_consultation_type]",
      formData.consultation_type
    );
    apiData.append("contact[email]", formData.email);
    apiData.append("asset_key", formData.asset_key);

    try {
      const response = await fetch(
        "https://physiotattva-org.myfreshworks.com/crm/sales/smart_form/create_entity?file_attachments_present=false",
        {
          method: "POST",
          body: apiData,
          headers: {
            Accept: "application/json",
          },
        }
      );

      if (response.ok) {
        alert("Form submitted successfully!");
        setFormData({
          first_name: "",
          last_name: "",
          mobile_number: "",
          consultation_type: "Virtual/online consultation",
          email: "",
          asset_key:
            "10225d1d52d93710486bc64ab9f65fccef25cadfb68f0ea2c39e5e064326f724",
        });
      } else {
        const errorData = await response.json();
        alert(
          `Error submitting form: ${errorData.message || "Please try again."}`
        );
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  const handleBookConsultation = () => {
    window.open(
      "https://app.physiotattva247.com/doctors-list?redirect=/doctors-list",
      "_blank"
    );
  };

  return (
    <>
      <div className={styles.mainContent}>
        <section className={styles.contactSection} ref={formRef}>
          <div className={styles.contactInfo}>
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/c286dcd52a0c884faeb90f9cb31dc10ee3785c48523ddd56677fb3e21811ca34?apiKey=fd9c0163575d41b080a751265adc9b1c&"
              alt="Company logo"
              className={styles.logo}
            />
            <p className={styles.mission}>
              Our mission is to leverage AI/ML to improve the human health
              experience.
            </p>
            <div className={styles.appStores}>
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/88669524b6c199a0317390ba638e81db5feb14e88c88173db719e316be830b5c?apiKey=fd9c0163575d41b080a751265adc9b1c&"
                alt="App Store"
                className={styles.appStoreButton}
              />
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/981faccc51172794d2407edb4056d8fc9462737c066b2eb058b54d9cfd99aa33?apiKey=fd9c0163575d41b080a751265adc9b1c&"
                alt="Google Play"
                className={styles.playStoreButton}
              />
            </div>
            <div className={styles.socialLinks}>
              <a
                href="https://www.facebook.com/physiotattva/"
                aria-label="Facebook"
              >
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/df7b6370ecd0191a58cfbf82585cefd0da7dc521e5f5d137639c5e87996bef24?apiKey=fd9c0163575d41b080a751265adc9b1c&"
                  alt=""
                  className={styles.socialIcon}
                />
              </a>
              <a
                href="https://www.instagram.com/physiotattva/"
                aria-label="Instagram"
              >
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/2268fc8584cb458a9a28a7b05cc0f6c0daa4789882c8c0c75cb27b0a3a4c94b3?apiKey=fd9c0163575d41b080a751265adc9b1c&"
                  alt=""
                  className={styles.socialIcon}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/physiotattva/"
                aria-label="LinkedIn"
              >
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/4acca5067d54a6d256111d98d36a46dbfc242e61411dbcab29082a1542a41023?apiKey=fd9c0163575d41b080a751265adc9b1c&"
                  alt=""
                  className={styles.socialIcon}
                />
              </a>
              <a
                href="https://youtube.com/@physiotattva8619?si=mEHI1dAj0JAHp1do"
                aria-label="YouTube"
              >
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9f7edc08131147ac6f9a0b525e4d67425b71a56a526e90b45ff69bc43c3210c6?apiKey=fd9c0163575d41b080a751265adc9b1c&"
                  alt=""
                  className={styles.socialIcon}
                />
              </a>
            </div>
          </div>
          <form className={styles.contactForm} onSubmit={handleSubmit}>
            <div className={styles.formRow}>
              <input
                type="text"
                id="first_name"
                name="first_name"
                placeholder="First Name"
                className={styles.formInput}
                required
                value={formData.first_name}
                onChange={handleInputChange}
              />
              <input
                type="text"
                id="last_name"
                name="last_name"
                placeholder="Last Name"
                className={styles.formInput}
                required
                value={formData.last_name}
                onChange={handleInputChange}
              />
            </div>
            <div className={styles.formRow}>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email ID"
                className={styles.formInput}
                required
                value={formData.email}
                onChange={handleInputChange}
              />
              <div className={styles.phoneInput}>
                <span className={styles.countryCode}>+91</span>
                <input
                  type="tel"
                  id="mobile_number"
                  name="mobile_number"
                  placeholder="Mobile Number"
                  className={styles.formInput}
                  required
                  value={formData.mobile_number}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className={styles.formRow}>
              <select
                id="consultation_type"
                name="consultation_type"
                className={`${styles.formInput} ${styles.selectInput}`}
                required
                value={formData.consultation_type}
                onChange={handleInputChange}
              >
                <option value="Virtual/online consultation">
                  Virtual/online consultation
                </option>
                <option value="In-Person/clinic based (Bangalore and Hyderabad - India)">
                  In-Person/clinic based (Bangalore and Hyderabad - India)
                </option>
                <option value="Home based consultation">
                  Home based consultation
                </option>
              </select>
            </div>

            <button type="submit" className={styles.submitButton}>
              Book a Consultation
            </button>
          </form>
        </section>
      </div>
    </>
  );
};

export default ContactForm;
