import React, { useRef, useEffect } from 'react';
import styles from './FeatureCard.module.css';

const FeatureCard = ({ imageSrc, title, description, iconSrc }) => {
  const cardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          cardRef.current.classList.add(styles.animate);
        }
      },
      { threshold: 0.1 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  return (
    <div ref={cardRef} className={styles.featureCard}>
      <img src={imageSrc} alt={title} className={styles.featureImage} />
      <div className={styles.featureContent}>
        <h3 className={styles.featureTitle}>{title}</h3>
        <p className={styles.featureDescription}>{description}</p>
        {/* <img src={iconSrc} alt="" className={styles.featureIcon} /> */}
      </div>
    </div>
  );
};

export default FeatureCard;