import { useEffect, useCallback, useRef } from "react";
import ReactGA from "react-ga4";

const useAnalytics = () => {
  const isInitialized = useRef(false);

  useEffect(() => {
    if (!isInitialized.current) {
      ReactGA.initialize("G-5RTW44YWGE");
      console.log("Google Analytics initialized");
      isInitialized.current = true;
    }
  }, []);

  const lastTrackedPath = useRef(null);

  const trackPageView = useCallback((path) => {
    console.log(`Analytics - Tracking page view: ${path}`);
    ReactGA.send({ hitType: "pageview", page: path });
  }, []);

  const trackEvent = useCallback((category, action, label, value) => {
    console.log(`Analytics - Tracking event:`);
    console.log(`  Category: ${category}`);
    console.log(`  Action: ${action}`);
    console.log(`  Label: ${label}`);
    console.log(`  Value: ${value}`);
    ReactGA.event({ category, action, label, value });
  }, []);

  const setUserId = useCallback((userId) => {
    console.log(`Analytics - Setting user ID: ${userId}`);
    ReactGA.set({ userId });
  }, []);

  const setUserProperties = useCallback((properties) => {
    console.log(`Analytics - Setting user properties:`);
    console.log(properties);
    ReactGA.set(properties);
  }, []);

  return {
    trackPageView,
    trackEvent,
    setUserId,
    setUserProperties,
  };
};

export default useAnalytics;
