import React, { useState, useEffect } from "react";
import styles from "./Header.module.css";
import usePixel from "../../hooks/usePixel";

const Header = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const { fbtrackPageView, fbtrackViewContent } = usePixel();

  const controlHeader = () => {
    if (typeof window !== "undefined") {
      // if (window.scrollY > lastScrollY) { // if scroll down hide the header
      //   setIsVisible(true);
      // } else { // if scroll up show the header
      //   setIsVisible(true);
      // }
      setIsVisible(true);
      // Update last scroll position
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlHeader);

      // Cleanup function
      return () => {
        window.removeEventListener("scroll", controlHeader);
      };
    }
  }, [lastScrollY]);
  const handleSignIn = () => {
    window.open("https://www.app.physiotattva247.com/login", "_self");
  };
  const handleBookConsultation = () => {
    fbtrackViewContent({
      content_type: "doctor_listing",
      content_name: "Doctor Listing Page",
    });

    window.open("https://www.app.physiotattva247.com/get-started/", "_blank");
  };

  return (
    <header
      className={`${styles.header} ${
        isVisible ? styles.headerVisible : styles.headerHidden
      }`}
    >
      <div className={styles.content}>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2604a26b922db7ba516aacb729045baa3f3fb9bf206258e9b0176c037f96d751?apiKey=fd9c0163575d41b080a751265adc9b1c&"
          alt="Company logo"
          className={styles.logo}
        />
        <div className={styles.headerButtons}>
          <button className={styles.signInButton} onClick={handleSignIn}>
            Sign in
          </button>
          <button
            className={styles.downloadButton}
            onClick={handleBookConsultation}
          >
            Speak to AI Dr. Riya
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
