import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const BOOK_INDIVIDUAL_APPOINTMENT =
  "https://erp.physiotattva.com/book_appointment";
const BEARER_TOKEN = "jshcuhcyyy7yys8w8ugdhsbcbbbbhuys7y7ytwfs";

export default function BookAppointment() {
  const [status, setStatus] = useState("initializing");
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const apiCallInProgress = useRef(false);

  useEffect(() => {
    let isMounted = true;

    const bookAppointment = async () => {
      // If API call is already in progress, skip
      if (apiCallInProgress.current) return;

      try {
        // Set flag to indicate API call is starting
        apiCallInProgress.current = true;

        if (isMounted) setStatus("processing");

        // Get URL parameters 
        const params = new URLSearchParams(location.search);
        const timeSlot = params.get("timeSlot");

        // Convert URL params to booking data object with proper type parsing
        const bookingData = {};
        params.forEach((value, key) => {
          if (key !== "timeSlot") {
            if (key === "consultation_type_id" || key === "lead_id") {
              bookingData[key] = parseInt(value, 10);
              if (isNaN(bookingData[key])) {
                throw new Error(`Invalid ${key} provided`);
              }
            } else {
              bookingData[key] = value;
            }
          }
        });

        if (!timeSlot || Object.keys(bookingData).length === 0) {
          throw new Error("Missing required booking information");
        }

        console.log("Processed booking data:", bookingData);

        const response = await axios.put(
          `${BOOK_INDIVIDUAL_APPOINTMENT}/${timeSlot}`,
          bookingData,
          {
            headers: {
              Authorization: `Bearer ${BEARER_TOKEN}`,
              "Content-Type": "application/json",
            },
          }
        );

        const appointmentData = response.data.result[0];
        console.log("appointment data is ", appointmentData);

        const paymentUrl = appointmentData["slot.booking"]?.online_payment_url;

        if (paymentUrl && isMounted) {
          setStatus("redirecting");
          window.location.href = paymentUrl;
        } else {
          throw new Error("Payment URL not found");
        }
      } catch (err) {
        if (isMounted) {
          setStatus("error");
          setError(err.message || "Failed to book appointment");
          console.error("Booking error:", err);
        }
      } finally {
        // Reset the flag when API call is complete
        apiCallInProgress.current = false;
      }
    };

    bookAppointment();

    // Cleanup function
    return () => {
      isMounted = false;
      apiCallInProgress.current = false;
    };
  }, [location.search]);

  const statusMessages = {
    initializing: "Initializing booking process...",
    processing: "Processing your appointment...",
    redirecting: "Redirecting to payment...",
    error: `Error: ${error}`,
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full space-y-8 p-6 bg-white rounded-xl shadow-lg">
        <div className="text-center">
          <h2 className="mt-6 text-3xl font-bold text-gray-900">
            Processing your payment
          </h2>

          <div className="mt-4 space-y-4">
            {status === "error" ? (
              <>
                <p className="text-red-600">{statusMessages[status]}</p>
                <button
                  onClick={() => navigate(-1)}
                  className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-200"
                >
                  Go Back
                </button>
              </>
            ) : (
              <div className="space-y-4">
                <div className="animate-spin rounded-full h-12 w-12 border-b-4 border-blue-600 mx-auto" />
                <p className="text-gray-600 text-lg">
                  {statusMessages[status]}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
